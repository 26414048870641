angular.module('autographDisplayApp')
    .controller('displayCtrl', ['$scope', '$timeout', '$log', 'autographSvc', function($scope, $timeout, $log, autographSvc) {

        // shared scope
        $scope.status = {};
        $scope.settings = {};

        var getParam = function(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        $scope.guidebookMode = getParam('guidebook');


        var getData = function() {
            autographSvc.getData().then(function(data) {
                $scope.guests = data.guests;
                $scope.settings = data.settings;

                $timeout(function() {
                    getData();
                    $log.info('['+new Date()+'] Display data refreshed!');
                }, $scope.settings.refreshInterval * 1000);
            });
        };

        getData();

    }]);
