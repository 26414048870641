angular.module('autographDisplayApp')
    .directive('guestInfo', [function() {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                guest: '=',
                settings: '='
            },
            templateUrl: 'guestInfo.tpl.html',
            link: function(scope, element, attrs) {
                scope.guestAvatarSrc = function(guestName) {
                    return '/images/' + guestName.toLowerCase().replace(/ /g, '-') + '.jpg';
                }
            }
        }
    }]);
