angular.module("autographDisplayApp").factory("autographSvc", [
  "$http",
  "$q",
  "$log",
  "CacheFactory",
  function($http, $q, $log, CacheFactory) {
    var autographApi = {};
    var baseUrl = "https://intel.bronycon.org";
    var endpoint = "/ajax/guidebook/autographs";
    var apiCache;

    // if doesn't exist, create a new
    if (!CacheFactory.get("apiCache")) {
      apiCache = CacheFactory("apiCache", {
        storageMode: "localStorage"
      });
    }

    autographApi.seedData = function() {
      return {
        guests: [
          {
            name: "Andrea Libman",
            price: {
              autograph: "20",
              photo: "20",
              combo: "30"
            },
            inSession: false,
            coreHours: {
              friday: "12:30pm–2:00pm, 4:00pm–5:30pm",
              saturday: "10:30am–12:00pm, 2:30pm–4:00pm",
              sunday: "11:30am–1:00pm, 2:30pm–4:00pm"
            }
          },
          {
            name: "Bonnie Zacherle",
            price: {
              autograph: "20",
              photo: "20",
              combo: "30"
            },
            inSession: false,
            coreHours: {
              friday: "2:00pm–3:30pm",
              saturday: "1:00pm–2:30pm",
              sunday: "2:00pm–3:30pm"
            }
          },
          {
            name: "Britt McKillip",
            price: {
              autograph: "20",
              photo: "20",
              combo: "30"
            },
            inSession: false,
            coreHours: {
              friday: "2:00pm–3:30pm",
              saturday: "12:00pm–1:30pm",
              sunday: "1:00pm–2:30pm"
            }
          },
          {
            name: "Cathy Weseluck",
            price: {
              autograph: "20",
              photo: "20",
              combo: "30"
            },
            inSession: false,
            coreHours: {
              friday: "4:30pm–6:00pm",
              saturday: "2:30pm–4:00pm",
              sunday: "11:30am–1:00pm"
            }
          },
          {
            name: "Gabriel Brown",
            price: {
              autograph: "20",
              photo: "20",
              combo: "30"
            },
            inSession: false,
            coreHours: {
              friday: "11:00am–12:00pm, 2:00pm–3:30pm",
              saturday: "10:30am–12:00pm, 2:30pm–4:00pm, 9:30pm–11:00pm",
              sunday: "1:00pm–2:30pm"
            }
          },
          {
            name: "Jayson Thiessen",
            price: {
              autograph: "20",
              photo: "20",
              combo: "30"
            },
            inSession: false,
            coreHours: {
              friday: "1:00pm–2:00pm, 4:00pm–6:00pm",
              saturday: "12:00pm–1:30pm, 4:00pm–5:30pm",
              sunday: "10:00am–11:30am, 2:00pm–3:30pm"
            }
          },
          {
            name: "Lauren Faust",
            price: {
              autograph: "20",
              photo: "20",
              combo: "30"
            },
            inSession: false,
            coreHours: {
              friday: "1:00pm–2:00pm, 4:00pm–6:00pm",
              saturday: "1:00pm–2:30pm, 5:30pm–7:00pm",
              sunday: "11:30am–1:00pm"
            }
          },
          {
            name: "M.A. Larson",
            price: {
              autograph: "20",
              photo: "20",
              combo: "30"
            },
            inSession: false,
            coreHours: {
              friday: "2:00pm–3:30pm, 4:00pm–5:30pm",
              saturday: "12:00pm–1:30pm, 4:00pm–5:30pm",
              sunday: "10:00am–11:30am, 2:30pm–4:00pm"
            }
          },
          {
            name: "Michelle Creber",
            price: {
              autograph: "20",
              photo: "20",
              combo: "30"
            },
            inSession: false,
            coreHours: {
              friday: "11:00am–12:00pm, 2:00pm–3:30pm",
              saturday: "10:30am–12:00pm, 2:30pm–4:00pm, 9:30pm–11:00pm",
              sunday: "1:00pm–2:30pm"
            }
          },
          {
            name: "Nicole Oliver",
            price: {
              autograph: "20",
              photo: "20",
              combo: "30"
            },
            inSession: false,
            coreHours: {
              friday: "11:00am–12:30pm",
              saturday: "2:30pm–4:00pm",
              sunday: "11:30am–1:00pm"
            }
          },
          {
            name: "Rebecca Shoichet",
            price: {
              autograph: "20",
              photo: "20",
              combo: "30"
            },
            inSession: false,
            coreHours: {
              friday: "11:00am–12:00pm, 2:00pm–3:30pm",
              saturday: "2:30pm–4:00pm",
              sunday: "1:00pm–2:30pm"
            }
          }
        ],
        settings: {
          activeSessionText: "In-Session",
          alertText: false,
          inactiveSessionText: "Out",
          preventBurnIn: false,
          refreshInterval: "300",
          showAvatar: false,
          showCoreHours: "true",
          showInSession: "true",
          showName: "true",
          showPriceAutograph: "true",
          showPriceCombo: "true",
          showPricePhoto: "true"
        }
      };
    };

    autographApi.getData = function() {
      var defer = $q.defer();

      $http
        .get(baseUrl + endpoint)
        .then(function(data) {
          $log.info("[" + new Date() + "] API call successful and cached!");
          apiCache.put("autographData", data);
          defer.resolve(data.data);
        })
        .catch(function(err) {
          if (apiCache.get("autographData")) {
            $log.warn(
              "[" +
                new Date() +
                "] Issue with API call, serving up local cache."
            );
            defer.resolve(apiCache.get("autographData").data);
          } else {
            $log.error(
              "[" +
                new Date() +
                "] API problem with no cache, serving static seed data."
            );
            defer.resolve(autographApi.seedData());
          }
        });

      return defer.promise;
    };

    return autographApi;
  }
]);
